<template>
  <div class="home">
    <headerComponent :data="getHomePageHeader" />
    <trustedComaniesComponent />
    <ourSolutionComponent :solutions="getOurSolutionsHome" />
    <ourImapctComponent />
    <ourCoursesComponent />
    <partnersStatementsComponent />
    <empoweringLanguageComponent />
    <successStoriesComponent />
    <transformLanguageComponent />
    <latestInsightsComponent />
    <newsLetterComponent />
    <footerComponent />
  </div>
</template>

<script>
import headerComponent from "@/components/headerComponent.vue";
import trustedComaniesComponent from "@/components/trustedComaniesComponent.vue";
import ourSolutionComponent from "@/components/ourSolutionComponent.vue";
import ourImapctComponent from "@/components/ourImpactComponent.vue";
import ourCoursesComponent from "@/components/ourCoursesComponent.vue";
import partnersStatementsComponent from "@/components/partnersStatementsComponent.vue";
import empoweringLanguageComponent from "@/components/empoweringLanguageComponent.vue";
import successStoriesComponent from "@/components/successStoriesComponent.vue";
import transformLanguageComponent from "@/components/transformLanguageComponent.vue";
import latestInsightsComponent from "@/components/latestInsightsComponent.vue";
import newsLetterComponent from "@/components/newsLetterComponent.vue";
import footerComponent from "@/components/footerComponent.vue";
import { mapGetters, mapMutations } from "vuex";
import axios from "axios";

export default {
  name: "HomeView",
  components: {
    headerComponent,
    trustedComaniesComponent,
    ourSolutionComponent,
    ourImapctComponent,
    ourCoursesComponent,
    partnersStatementsComponent,
    empoweringLanguageComponent,
    successStoriesComponent,
    transformLanguageComponent,
    latestInsightsComponent,
    newsLetterComponent,
    footerComponent,
  },
  computed: {
    ...mapGetters(["getHomePageHeader", "getOurSolutionsHome"]),
  },
  mounted() {
    this.getCourses();
    this.getArticlesData();
    this.getCaseStudiesData();
  },
  methods: {
    ...mapMutations(["setCourses", "setArticles", "setCaseStudies"]),
    getCourses() {
      axios
        .get(
          "https://cdn.contentful.com/spaces/8nqnktj51aoq/environments/master/entries?access_token=njkIU16uuU7MPa5hOvzUO4deV6DFYht3xdwj6KUge-k&content_type=course&limit=3&sys.id[in]=5CHzIZeRg535pCStwpq29j,AdlB4P4KbwvAEnKUZXNlO,3Sa4z73kZvYbOfvhpSgAPQ&order=sys.createdAt"
        )
        .then((response) => {
          this.setCourses(response.data.items);
        });
    },
    getArticlesData() {
      this.setArticles([]);
      axios
        .get(
          "https://cdn.contentful.com/spaces/8nqnktj51aoq/environments/master/entries?access_token=njkIU16uuU7MPa5hOvzUO4deV6DFYht3xdwj6KUge-k&content_type=blog&limit=3"
        )
        .then((response) => {
          this.setArticles(response.data.items);
        });
    },
    getCaseStudiesData() {
      this.loading = true;
      this.setCaseStudies([]);
      axios
        .get(
          "https://cdn.contentful.com/spaces/8nqnktj51aoq/environments/master/entries?access_token=njkIU16uuU7MPa5hOvzUO4deV6DFYht3xdwj6KUge-k&content_type=caseStudy&limit=3"
        )
        .then((response) => {
          this.loading = false;
          this.setCaseStudies(response.data.items);
          this.scrollToTop();
        });
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
