<template>
  <div
    class="header-menu-container"
    :style="hover === true ? { background: '#20202f61', height: '100vh' } : {}"
  >
    <div
      class="header-menu-wrapper"
      @mouseleave="hover = false"
      :class="
        hover
          ? tab === 'solutions'
            ? 'open-menu-solutions'
            : 'open-menu-resources'
          : ''
      "
    >
      <div class="row header-align-items">
        <div class="col-8">
          <div class="header-menu-options-left">
            <img
              class="header-menu-image"
              :src="`${
                !hover
                  ? require('@/assets/images/logo.png')
                  : require('@/assets/images/logoColored.png')
              }`"
              alt="header logo image"
              @click="$router.push('/')"
            />
            <div
              class="header-menu-options-left header-flex-grow header-align-items header-desktop-menu-buttons"
            >
              <div
                class="header-menu-options"
                :class="hover ? 'open-menu-text' : ''"
                @click="$router.push('/spanish-courses-overview')"
              >
                Courses
              </div>
              <div
                class="header-menu-options"
                @mouseenter="
                  hover = true;
                  tab = 'solutions';
                "
              >
                <div :class="hover ? 'open-menu-text' : ''">
                  Solutions <i class="mdi mdi-menu-down"></i>
                </div>
              </div>
              <div
                class="header-menu-options"
                :class="hover ? 'open-menu-text' : ''"
                @click="$router.push('/about-comligo')"
              >
                About us
              </div>
              <div
                class="header-menu-options"
                :class="hover ? 'open-menu-text' : ''"
                @click="$router.push('/spanish-course-pricing')"
              >
                Pricing
              </div>
              <div
                class="header-menu-options"
                @mouseenter="
                  hover = true;
                  tab = 'resources';
                "
              >
                <div :class="hover ? 'open-menu-text' : ''">
                  Resources <i class="mdi mdi-menu-down"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="header-menu-buttons">
            <md-outlined-button
              @click="$router.push('/contact-comligo')"
              class="header-menu-left-button discover-more-button"
              :class="hover ? 'open-menu-button' : ''"
              >Contact us</md-outlined-button
            >
            <md-filled-tonal-button @click="$router.push('/book-demo')">
              Book a demo class
              <i class="mdi mdi-arrow-right"></i>
            </md-filled-tonal-button>
          </div>
          <div
            :class="
              hover
                ? 'header-menu-mobile-icon-active'
                : 'header-menu-mobile-icon-inactive'
            "
            @click="
              hover = !hover;
              tab = 'resources';
            "
          >
            <i class="mdi mdi-menu"></i>
          </div>
        </div>
      </div>
      <div class="header-menu-options-inner header-flex-grow" v-if="hover">
        <div
          class="header-menu-options"
          :class="hover ? 'open-menu-text' : ''"
          @click="$router.push('/spanish-courses-overview')"
        >
          Courses
        </div>
        <div
          class="header-menu-options"
          @mouseenter="
            hover = true;
            tab = 'solutions';
          "
        >
          <div
            class="open-menu-text"
            :class="tab === 'solutions' ? 'open-menu-text-active' : ''"
          >
            Solutions <i class="mdi mdi-menu-down"></i>
          </div>
        </div>
        <div
          class="header-menu-options"
          :class="hover ? 'open-menu-text' : ''"
          @click="$router.push('/about-comligo')"
        >
          About us
        </div>
        <div
          class="header-menu-options"
          :class="hover ? 'open-menu-text' : ''"
          @click="$router.push('/spanish-course-pricing')"
        >
          Pricing
        </div>
        <div
          class="header-menu-options"
          @mouseenter="
            hover = true;
            tab = 'resources';
          "
        >
          <div
            class="open-menu-text"
            :class="tab === 'resources' ? 'open-menu-text-active' : ''"
          >
            Resources <i class="mdi mdi-menu-down"></i>
          </div>
        </div>
      </div>
      <div v-if="hover && tab === 'solutions'" class="header-submenus">
        <div class="row">
          <div class="col-4 header-submenu-sections">
            <div class="header-submenu-title">Solutions</div>
            <div class="header-submenu-body">
              Discover our range of solutions tailored to meet your specific
              needs. Whether you are in education, the private sector, or the
              public sector, our innovative tools and resources are designed to
              enhance efficiency, productivity, and success.
            </div>
            <md-filled-tonal-button
              class="header-menu-hover-button"
              @click="$router.push('/spanish-courses-overview')"
            >
              Check our courses
              <i class="mdi mdi-arrow-right"></i>
            </md-filled-tonal-button>
          </div>
          <div class="col-4 header-submenu-sections">
            <div
              class="header-submenu-title-links"
              @click="$router.push('/k-12-schools')"
            >
              For K-12 education
            </div>
            <div class="header-submenu-body">
              Tailored solutions to enhance teaching, learning, and
              administrative efficiency in K-12.
            </div>
            <div
              class="header-submenu-title-links"
              @click="$router.push('/ib-schools')"
            >
              For IB schools
            </div>
            <div class="header-submenu-body">
              Specialized solutions for managing IB curriculum, assessments, and
              educator support.
            </div>
            <div
              class="header-submenu-title-links"
              @click="$router.push('/private-sector-ngos')"
            >
              For private sector
            </div>
            <div class="header-submenu-body">
              Comprehensive solutions for project management, customer
              relations, and operational efficiency.
            </div>
          </div>
          <div class="col-4 header-submenu-sections">
            <div
              class="header-submenu-title-links"
              @click="$router.push('/higher-education')"
            >
              For higher education
            </div>
            <div class="header-submenu-body">
              Advanced solutions for academic management, research
              collaboration, and student engagement.
            </div>
            <div
              class="header-submenu-title-links"
              @click="$router.push('/public-sector')"
            >
              For public sector and non-profits
            </div>
            <div class="header-submenu-body">
              Efficient solutions for management, reporting, and communication
              in public service and non-profits.
            </div>
          </div>
        </div>
      </div>
      <div v-if="hover && tab === 'resources'" class="header-submenus">
        <div class="row">
          <div class="col-4 header-submenu-sections">
            <div class="header-submenu-title">Resources</div>
            <div class="header-submenu-body">
              Explore our collection of valuable resources designed to support
              your growth and success. From insightful articles to comprehensive
              guides, eBooks, and events, we provide the tools and knowledge you
              need to excel.
            </div>
            <md-filled-tonal-button
              class="header-menu-hover-button"
              @click="$router.push('/spanish-learning-resources')"
            >
              Check our resources
              <i class="mdi mdi-arrow-right"></i>
            </md-filled-tonal-button>
          </div>
          <div class="col-4 header-submenu-sections">
            <div
              class="header-submenu-title-links"
              @click="$router.push('/spanish-learning-articles')"
            >
              Articles
            </div>
            <div class="header-submenu-body">
              Access expert-written articles on industry trends, best practices,
              and innovative solutions.
            </div>
            <div
              class="header-submenu-title-links"
              @click="$router.push('/spanish-learning-ebooks')"
            >
              eBooks
            </div>
            <div class="header-submenu-body">
              Explore our library of eBooks, offering detailed insights and
              practical advice on various subjects.
            </div>
          </div>
          <div class="col-4 header-submenu-sections">
            <div
              class="header-submenu-title-links"
              @click="$router.push('/comligo-spanish-events')"
            >
              Events
            </div>
            <div class="header-submenu-body">
              Stay informed about upcoming events, webinars, and workshops to
              enhance your skills and network.
            </div>
            <div
              class="header-submenu-title-links"
              @click="$router.push('/spanish-case-studies')"
            >
              Case studies
            </div>
            <div class="header-submenu-body">Discover our success stories</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderMenu",
  data() {
    return {
      hover: false,
      tab: "",
    };
  },
};
</script>

<style></style>
