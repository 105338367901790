<template>
  <div class="footer-container">
    <div class="row">
      <div class="col-lg-3 col-4 footer-cols">
        <img
          class="footer-logo"
          :src="require('@/assets/images/logoColoredWhite.png')"
          alt="footer logo image"
        />
        <div class="footer-col-1-text">CONNECT</div>
        <div class="footer-col-1-text">LEARN</div>
        <div class="footer-col-1-text">GROW</div>
        <div class="footer-col-1-button">
          <md-filled-tonal-button class="record-list-read-more-button">
            Get Started
            <i class="mdi mdi-arrow-right"></i>
          </md-filled-tonal-button>
        </div>
      </div>
      <div class="col-lg-2 col-4 footer-cols">
        <div class="footer-cols-title">Courses</div>
        <div
          class="footer-cols-links"
          @click="$router.push('/spanish-courses-overview')"
        >
          For Adults
        </div>
        <div
          class="footer-cols-links"
          @click="$router.push('/spanish-courses-overview')"
        >
          For Teenagers
        </div>
        <div
          class="footer-cols-links"
          @click="$router.push('/spanish-courses-overview')"
        >
          For Kids
        </div>
      </div>
      <div class="col-lg-2 col-4 footer-cols">
        <div class="footer-cols-title">Solutions</div>
        <div class="footer-cols-links" @click="$router.push('/k-12-schools')">
          For K-12 education
        </div>
        <div class="footer-cols-links" @click="$router.push('/ib-schools')">
          For IB schools
        </div>
        <div
          class="footer-cols-links"
          @click="$router.push('/higher-education')"
        >
          For higher education
        </div>
        <div
          class="footer-cols-links"
          @click="$router.push('/private-sector-ngos')"
        >
          For private sector
        </div>
        <div class="footer-cols-links" @click="$router.push('/public-sector')">
          For public sector and non-profits
        </div>
      </div>
      <div class="col-lg-2 col-4 footer-cols">
        <div class="footer-cols-title" @click="$router.push('/about-comligo')">
          About us
        </div>
        <div
          class="footer-cols-links"
          @click="$router.push('/about-comligo/academic-team')"
        >
          Our Academic team
        </div>
        <div
          class="footer-cols-links"
          @click="$router.push('/about-comligo/teachers')"
        >
          Our teachers
        </div>
      </div>
      <div class="col-lg-2 col-4 footer-cols">
        <div class="footer-cols-title">Resources</div>
        <div
          class="footer-cols-links"
          @click="$router.push('/spanish-learning-articles')"
        >
          Articles
        </div>
        <div
          class="footer-cols-links"
          @click="$router.push('/spanish-learning-resources')"
        >
          Guides
        </div>
        <div
          class="footer-cols-links"
          @click="$router.push('/spanish-learning-ebooks')"
        >
          Ebooks
        </div>
        <div
          class="footer-cols-links"
          @click="$router.push('/comligo-spanish-events')"
        >
          Events
        </div>
      </div>
      <div class="col-lg-1 col-4 footer-cols">
        <div class="footer-cols-title">Contact us</div>
        <div
          class="footer-cols-links"
          @click="$router.push('/contact-comligo')"
        >
          Contact
        </div>
        <div class="footer-cols-links" @click="$router.push('/faqs')">FAQs</div>
      </div>
    </div>
    <div class="row footer-bottom-links">
      <div
        class="footer-cols-links col-lg-2 col-4"
        @click="$router.push('/terms-and-conditions')"
      >
        Terms and Conditions
      </div>
      <div
        class="footer-cols-links col-lg-2 col-4"
        @click="$router.push('/privacy-policy')"
      >
        Privacy Policy
      </div>
      <div
        class="footer-cols-links col-lg-2 col-4"
        @click="$router.push('/cancellation-policy')"
      >
        Cancellation Policy
      </div>
      <div
        class="footer-cols-links col-lg-2 col-4"
        @click="$router.push('/accessibility-policy')"
      >
        Accessibility Policy
      </div>
      <div
        class="footer-cols-links col-lg-2 col-4"
        @click="$router.push('/non-discrimination-policy')"
      >
        Non-discrimination Policy
      </div>
      <div
        class="footer-cols-links col-lg-2 col-4"
        @click="$router.push('/cookies-policy')"
      >
        Cookies Policy
      </div>
      <div class="footer-cols-links col-lg-2 col-4" @click="setDialog(true)">
        Cookies Consent Manager
      </div>
    </div>
    <div class="footer-copyright">
      <div class="footer-cols-links mr-auto">
        © 2024 Comligo. All rights reserved.
      </div>
      <div class="footer-icons-group">
        <div class="footer-icons-button">
          <i class="mdi mdi-facebook footer-icons"></i>
        </div>
        <div class="footer-icons-button">
          <i class="mdi mdi-linkedin footer-icons"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "FooterComponent",
  methods: {
    ...mapMutations(["setConsent", "setDialog"]),
  },
};
</script>

<style></style>
