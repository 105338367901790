<template>
  <div class="success-stories-container">
    <div class="success-stories-title">Success stories</div>
    <div class="row success-stories-swiper">
      <div class="col-lg-1 col-2 success-stories-buttons">
        <div class="partner-statements-navigation-buttons swiper-button-prev">
          <i class="mdi mdi-arrow-left"></i>
        </div>
      </div>
      <div class="col-lg-10 col-8">
        <swiper
          ref="swiperRef"
          :slides-per-view="1"
          :space-between="50"
          :loop="true"
          :pagination="{
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
          }"
          :speed="500"
          :navigation="{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }"
        >
          <swiper-slide v-for="(slide, i) in getCaseStudies" :key="i">
            <div class="success-stories-wrapper">
              <div class="success-stories-sections-left">
                <img
                  :src="images[i]"
                  class="success-stories-slide-image"
                  alt="success stories thumbnail"
                />
              </div>
              <div class="success-stories-sections-right">
                <div
                  v-for="(item, x) in slide?.fields?.listItem?.content"
                  :key="x"
                >
                  <div v-if="x === 1" class="success-stories-slide-title">
                    {{ item?.content[0]?.value }}
                  </div>
                  <div v-if="x === 2" class="success-stories-slide-description">
                    {{ item?.content[0]?.value }}
                  </div>
                  <div
                    v-if="
                      [2, 3].includes(x) &&
                      (item?.content[0]?.value.toLowerCase().includes('read') ||
                        item?.content[1]?.value.toLowerCase().includes('read'))
                    "
                    class="success-stories-slide-title"
                  >
                    <md-filled-tonal-button
                      class="success-stories-slide-button"
                      @click="
                        $router.push(
                          `/case-study/${
                            slide?.fields?.slug
                              ? slide?.fields?.slug
                              : slide?.sys?.id
                          }`
                        )
                      "
                    >
                      {{ item?.content[1]?.value }}{{ item?.content[0]?.value }}
                      <i class="mdi mdi-arrow-right"></i>
                    </md-filled-tonal-button>
                  </div>
                </div>
              </div>
            </div>
          </swiper-slide>
          <div class="swiper-pagination" />
        </swiper>
      </div>
      <div class="col-lg-1 col-2 success-stories-buttons">
        <div class="partner-statements-navigation-buttons swiper-button-next">
          <i class="mdi mdi-arrow-right"></i>
        </div>
      </div>
    </div>
    <md-filled-tonal-button
      class="get-started-button"
      @click="$router.push('/spanish-case-studies')"
    >
      Read our case studies
      <i class="mdi mdi-arrow-right"></i>
    </md-filled-tonal-button>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper/core";
import { mapGetters } from "vuex";
import axios from "axios";

SwiperCore.use([Autoplay, Pagination, Navigation]);

export default {
  name: "SuccessStories",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      images: [],
    };
  },
  computed: {
    ...mapGetters(["gethomeCaseStudies", "getCaseStudies"]),
  },
  watch: {
    "$store.state.caseStudies": function () {
      this.fetchImages();
    },
  },
  mounted() {
    if (this.getCaseStudies.length > 0) {
      this.fetchImages();
    }
  },
  methods: {
    fetchImages() {
      this.getCaseStudies.forEach((caseStudies, i) => {
        this.getImageURL(
          caseStudies?.fields?.listItem?.content[0]?.data?.target?.sys?.id,
          i
        );
      });
    },
    getImageURL(assetId, x) {
      if (assetId) {
        axios
          .get(
            `https://cdn.contentful.com/spaces/8nqnktj51aoq/assets/${assetId}?access_token=njkIU16uuU7MPa5hOvzUO4deV6DFYht3xdwj6KUge-k`
          )
          .then((response) => {
            this.images[x] = `https:${response.data.fields.file.url}`;
            console.log(this.images);
          });
      }
    },
  },
};
</script>

<style></style>
